import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Maintain Version Details", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "country", attrs: { "label": "Country", "reference": "web-analytics.common.countries", "placeholder": "Select Country", "source": "country", "rules": "required", "span": 12, "disabled": true, "value": _vm.entity.country, "form-item": "" } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { key: "sortOrder", attrs: { "rules": "required|numeric", "label": "Sort Order", "span": 12, "placeholder": "Type Sort Order", "value": _vm.entity.sortOrder, "form-item": "" }, on: { "change": _vm.onNewSortOrderChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "version", attrs: { "label": "Version", "reference": "web-analytics.common.dgp-versions", "placeholder": "Select Version", "source": "version", "source-label": "versionDesc", "parse-value": _vm.parseNumber, "rules": "required", "span": 12, "disabled": true, "value": _vm.entity.version, "form-item": "" } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { key: "alias", attrs: { "label": "Alias", "placeholder": "Type Alias", "rules": "required", "span": 12, "value": _vm.entity.alias, "form-item": "" }, on: { "change": _vm.onAliasChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { key: "finYear", attrs: { "year-only": true, "label": "Fin Year", "placeholder": "Select Year", "format-date": "YYYY", "rules": "required", "span": 12, "disabled": true, "value": _vm.entity.finYear, "form-item": "" } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditMaintainVersionDetails",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      sortOrder: "",
      newSortOrder: "",
      alias: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/web-analytics/version-details`, {
        country: this.entity.country,
        sortOrder: this.entity.sortOrder,
        newSortOrder: this.newSortOrder ? this.newSortOrder : this.entity.sortOrder,
        version: this.entity.version,
        alias: this.alias ? this.alias : this.entity.alias,
        finYear: this.entity.finYear
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      }).catch((err) => {
        this.isLoading = false;
        this.$notification["error"]({
          message: err.response.data.message || "Something went wrong, please try again!"
        });
      });
    },
    parseNumber(value) {
      return +value;
    },
    afterModalClose() {
      this.$router.push("/web-analytics/maintain-version-details");
    },
    onCancel() {
      this.visible = false;
    },
    onNewSortOrderChange(value) {
      this.newSortOrder = value;
    },
    onAliasChange(value) {
      this.alias = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditMaintainVersionDetails = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.dgp-versions", "api-url": _vm.apiUrl, "resource-id-name": "version" } }), _c("resource", { attrs: { "name": "web-analytics.version-details", "api-url": _vm.apiUrl, "edit": _vm.EditMaintainVersionDetails, "redirect-route": "/web-analytics/maintain-version-details" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateMaintainVersionDetails",
  data() {
    return {
      EditMaintainVersionDetails,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
